import {Circle, Fill, Stroke, Style} from 'ol/style';
import {Feature, Map, Overlay, View} from 'ol/index';
import {OSM, Vector as VectorSource} from 'ol/source';
import {Point} from 'ol/geom';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {useGeographic} from 'ol/proj';

useGeographic();

const center = [103.83525539970292, 1.3854766321024259];
const showflatLongLat = [103.83529831552332, 1.3854122836018143];

const mrtLongLat = [103.83679737126778, 1.3846235379903638];
const busLongLat = [103.83651925170484, 1.3849016428262528];
const expWayLongLat = [103.83335564973703, 1.3945670233423098];

const sch1LongLat = [103.83417861408232, 1.3748857544451583];
const sch2LongLat = [103.83270639986272, 1.3606069155862106];
const sch3LongLat = [103.8398555155703, 1.369551987828947];

const rec1LongLat = [103.83077854236512, 1.35499306328232];
const rec2LongLat = [103.84847198672912, 1.3697552393846943];
const rec3LongLat = [103.82626609688108, 1.3719336296042954];

const site = new Feature(new Point(center));
const showflat = new Feature(new Point(showflatLongLat));

const mrt = new Feature(new Point(mrtLongLat));
const bus = new Feature(new Point(busLongLat));
const expWay = new Feature(new Point(expWayLongLat));

const sch1 = new Feature(new Point(sch1LongLat));
const sch2 = new Feature(new Point(sch2LongLat));
const sch3 = new Feature(new Point(sch3LongLat));

const rec1 = new Feature(new Point(rec1LongLat));
const rec2 = new Feature(new Point(rec2LongLat));
const rec3 = new Feature(new Point(rec3LongLat));

const map = new Map({
  target: 'map',
  view: new View({
    center: center,
    zoom: 15,
    maxZoom: 18,
    minZoom: 14
  }),
  layers: [
    new TileLayer({
      source: new OSM(),
    }),
    new VectorLayer({
      source: new VectorSource({
        features: [site, showflat, mrt, bus, expWay, sch1, sch2, sch3, rec1, rec2, rec3],
      }),
      style: new Style({
        image: new Circle({
          radius: 6,
          fill: new Fill({color: 'red'}),
          stroke: new Stroke({color: '#CC2632'})
        }),
      }),
    }),
  ],  
});

/*window.onload = init;
function init()
{
  const map = new Map({
    target: 'map',
    view: new View({
      center: center,
      zoom: 15,
      maxZoom: 18,
      minZoom: 14
    }),
    layers: [
      new TileLayer({
        source: new OSM(),
      }),
      new VectorLayer({
        source: new VectorSource({
          features: [site, showflat, mrt, bus, expWay, sch1, sch2, sch3, rec1, rec2, rec3],
        }),
        style: new Style({
          image: new Circle({
            radius: 6,
            fill: new Fill({color: 'red'}),
            stroke: new Stroke({color: '#CC2632'})
          }),
        }),
      }),
    ],  
  });
}*/

const element = document.getElementById('popup');

const popup = new Overlay({
  element: element,
  positioning: 'bottom-center',
  stopEvent: false,
  offset: [0, -10],
});
map.addOverlay(popup);

function checkCoordinateDesc(coordinate, myCoordinate)
{
  var lon = coordinate[0].toFixed(4);
  var lat = coordinate[1].toFixed(4);
  var clon = myCoordinate[0].toFixed(4);
  var clat = myCoordinate[1].toFixed(4);

  if(lon == clon && lat == clat)
  {
    return true;
  }
  else
  {
    return false;
  }
}

function formatCoordinate(coordinate) {
  
  var desc = "";
  if(checkCoordinateDesc(coordinate, center)){ desc = "Lentor Modern"};
  if(checkCoordinateDesc(coordinate, showflatLongLat)){ desc = "Showflat"};

  if(checkCoordinateDesc(coordinate, mrtLongLat)){ desc = "Lentor MRT"};
  if(checkCoordinateDesc(coordinate, busLongLat)){ desc = "Nearest Bus Stop"};
  if(checkCoordinateDesc(coordinate, expWayLongLat)){ desc = "SLE"};

  if(checkCoordinateDesc(coordinate, sch1LongLat)){ desc = "CHIJ St Nicholas Girl's"};
  if(checkCoordinateDesc(coordinate, sch2LongLat)){ desc = "Ai Tong Primary"};
  if(checkCoordinateDesc(coordinate, sch3LongLat)){ desc = "Ang Mo Kio Primary"};

  if(checkCoordinateDesc(coordinate, rec1LongLat)){ desc = "Thomson Plaza"};
  if(checkCoordinateDesc(coordinate, rec2LongLat)){ desc = "Ang Mo Kio Hub"};
  if(checkCoordinateDesc(coordinate, rec3LongLat)){ desc = "Lower Peirce Reservoir"};


  return `
    <table>
      <tbody>
        <tr><td nowrap>${desc}</td></tr>
      </tbody>
    </table>`;
}
map.on('change:visible', function(event) {
  alert("Map Changed");  
});

map.on('click', function (event) {
  $(element).popover('dispose');

  const feature = map.getFeaturesAtPixel(event.pixel)[0];
  if (feature) {
    const coordinate = feature.getGeometry().getCoordinates();
    
    popup.setPosition([
      coordinate[0] + Math.round(event.coordinate[0] / 360) * 360,
      coordinate[1],
    ]);

    $(element).popover({
      container: element.parentElement,
      html: true,
      sanitize: false,
      content: formatCoordinate(coordinate),
      placement: 'top',
    });
    $(element).popover('show');
  }
});

map.on('pointermove', function (event) {
  if (map.hasFeatureAtPixel(event.pixel)) {
    map.getViewport().style.cursor = 'pointer';
  } else {
    map.getViewport().style.cursor = 'inherit';
  }
});

//Button Click Event
const showMapBtn = document.getElementById('showMapBtn');
showMapBtn.addEventListener(
  'click',
  function () {
    map.updateSize();
  },
  false
);

const showMapBtn2 = document.getElementById('showMapBtn2');
showMapBtn2.addEventListener(
  'click',
  function () {
    map.updateSize();
  },
  false
);

const centerSite = document.getElementById('centerSite');
centerSite.addEventListener(
  'click',
  function () {
    map.getView().setCenter(center);

    $(element).popover('dispose');

      popup.setPosition([
        center[0],
        center[1],
      ]);
      $(element).popover({
        container: element.parentElement,
        html: true,
        sanitize: false,
        content: formatCoordinate(center),
        placement: 'top',
      });
      $(element).popover('show');

  },
  false
);

const centerShowflat = document.getElementById('centerShowflat');
centerShowflat.addEventListener(
  'click',
  function () {
    map.getView().setCenter(showflatLongLat);    
    $(element).popover('dispose');

      popup.setPosition([
        showflatLongLat[0],
        showflatLongLat[1],
      ]);
      $(element).popover({
        container: element.parentElement,
        html: true,
        sanitize: false,
        content: formatCoordinate(showflatLongLat),
        placement: 'top',
      });
      $(element).popover('show');
  },
  false
);

const centerMRT = document.getElementById('centerMRT');
centerMRT.addEventListener(
  'click',
  function () {
    map.getView().setCenter(mrtLongLat);

    $(element).popover('dispose');

      popup.setPosition([
        mrtLongLat[0],
        mrtLongLat[1],
      ]);
      $(element).popover({
        container: element.parentElement,
        html: true,
        sanitize: false,
        content: formatCoordinate(mrtLongLat),
        placement: 'top',
      });
      $(element).popover('show');
  },
  false
);

const centerBus = document.getElementById('centerBus');
centerBus.addEventListener(
  'click',
  function () {
    map.getView().setCenter(busLongLat);

    $(element).popover('dispose');

      popup.setPosition([
        busLongLat[0],
        busLongLat[1],
      ]);
      $(element).popover({
        container: element.parentElement,
        html: true,
        sanitize: false,
        content: formatCoordinate(busLongLat),
        placement: 'top',
      });
      $(element).popover('show');
  },
  false
);

const centerExpWay = document.getElementById('centerExpWay');
centerExpWay.addEventListener(
  'click',
  function () {
    map.getView().setCenter(expWayLongLat);

    $(element).popover('dispose');

      popup.setPosition([
        expWayLongLat[0],
        expWayLongLat[1],
      ]);
      $(element).popover({
        container: element.parentElement,
        html: true,
        sanitize: false,
        content: formatCoordinate(expWayLongLat),
        placement: 'top',
      });
      $(element).popover('show');
  },
  false
);

const centerSch1 = document.getElementById('centerSch1');
centerSch1.addEventListener(
  'click',
  function () {
    map.getView().setCenter(sch1LongLat);

    $(element).popover('dispose');

      popup.setPosition([
        sch1LongLat[0],
        sch1LongLat[1],
      ]);
      $(element).popover({
        container: element.parentElement,
        html: true,
        sanitize: false,
        content: formatCoordinate(sch1LongLat),
        placement: 'top',
      });
      $(element).popover('show');
  },
  false
);

const centerSch2 = document.getElementById('centerSch2');
centerSch2.addEventListener(
  'click',
  function () {
    map.getView().setCenter(sch2LongLat);

    $(element).popover('dispose');

      popup.setPosition([
        sch2LongLat[0],
        sch2LongLat[1],
      ]);
      $(element).popover({
        container: element.parentElement,
        html: true,
        sanitize: false,
        content: formatCoordinate(sch2LongLat),
        placement: 'top',
      });
      $(element).popover('show');
  },
  false
);

const centerSch3 = document.getElementById('centerSch3');
centerSch3.addEventListener(
  'click',
  function () {
    map.getView().setCenter(sch3LongLat);

    $(element).popover('dispose');

      popup.setPosition([
        sch3LongLat[0],
        sch3LongLat[1],
      ]);
      $(element).popover({
        container: element.parentElement,
        html: true,
        sanitize: false,
        content: formatCoordinate(sch3LongLat),
        placement: 'top',
      });
      $(element).popover('show');
  },
  false
);

const centerRec1 = document.getElementById('centerRec1');
centerRec1.addEventListener(
  'click',
  function () {
    map.getView().setCenter(rec1LongLat);

    $(element).popover('dispose');

      popup.setPosition([
        rec1LongLat[0],
        rec1LongLat[1],
      ]);
      $(element).popover({
        container: element.parentElement,
        html: true,
        sanitize: false,
        content: formatCoordinate(rec1LongLat),
        placement: 'top',
      });
      $(element).popover('show');
  },
  false
);

const centerRec2 = document.getElementById('centerRec2');
centerRec2.addEventListener(
  'click',
  function () {
    map.getView().setCenter(rec2LongLat);

    $(element).popover('dispose');

      popup.setPosition([
        rec2LongLat[0],
        rec2LongLat[1],
      ]);
      $(element).popover({
        container: element.parentElement,
        html: true,
        sanitize: false,
        content: formatCoordinate(rec2LongLat),
        placement: 'top',
      });
      $(element).popover('show');
  },
  false
);

const centerRec3 = document.getElementById('centerRec3');
centerRec3.addEventListener(
  'click',
  function () {
    map.getView().setCenter(rec3LongLat);

    $(element).popover('dispose');

      popup.setPosition([
        rec3LongLat[0],
        rec3LongLat[1],
      ]);
      $(element).popover({
        container: element.parentElement,
        html: true,
        sanitize: false,
        content: formatCoordinate(rec3LongLat),
        placement: 'top',
      });
      $(element).popover('show');
  },
  false
);

